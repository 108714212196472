<template>
    <v-container
        id="street-lighting"
        fluid
        tag="section"
    >
      <v-alert
      dense
      text
      type="success"
      dismissible
      v-model="alert"
    >
      Section C <strong>saved</strong> successfully.
    </v-alert>
        <v-row justify="center">
            <v-col
            cols="12"
            md="12"
            >
                <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Section C: Lighting
                    </div>
                    <div class="display-2 font-weight-light">
                    43rd Street Lighting, Inc.
                    </div>
                    <div class="display-2 font-weight-light">
                    9636 85th Ave N, Maple Grove, MN 55369
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">
                        <v-row>
                        <v-col
                            cols="12"
                        >
                            <v-text-field :readonly="permissions === 'read:homeowner'"
                                style="width:50%;"
                                color="black"
                                label="Date"
                                type="date"
                            />
                        </v-col>

                        <v-col
                        cols="12"
                        md="6"
                        >
                            <v-text-field :readonly="permissions === 'read:homeowner'"
                                label="Homeowner 1 - First Name"
                                color="black"
                                v-model="this.selected_user.PrimaryFirstName"
                            />
                        </v-col>
                        <v-col
                        cols="12"
                        md="6"
                        >
                            <v-text-field :readonly="permissions === 'read:homeowner'"
                                label="Homeowner 1 - Last Name"
                                color="black"
                                v-model="this.selected_user.PrimaryLastName"
                            />
                        </v-col>
                          <v-col
                        cols="12"
                        md="4"
                        >
                            <v-text-field :readonly="permissions === 'read:homeowner'"
                                label="Homeowner 1 - Cell #"
                                color="black"
                                v-model="this.selected_user.CellNumber"
                            />
                        </v-col>
                        <v-col
                        cols="12"
                        md="4"
                        >
                            <v-text-field :readonly="permissions === 'read:homeowner'"
                                label="Homeowner 1 - Email"
                                color="black"
                                v-model="this.selected_user.PrimaryEmailAddress"
                            />
                        </v-col>

                        <v-col
                        cols="12"
                        md="6"
                        >
                            <v-text-field :readonly="permissions === 'read:homeowner'"
                                label="Homeowner 2 - First Name"
                                color="black"
                                v-model="this.selected_user.SecondaryFirstName"
                            />
                        </v-col>
                        <v-col
                        cols="12"
                        md="6"
                        >
                            <v-text-field :readonly="permissions === 'read:homeowner'"
                                label="Homeowner 2 - Last Name"
                                color="black"
                                v-model="this.selected_user.SecondaryLastName"
                            />
                        </v-col>

                        <v-col
                        cols="12"
                        >
                            <v-text-field :readonly="permissions === 'read:homeowner'"
                                label="Address & Legal"
                                color="black"
                                v-model="this.selected_user.Address"
                            />
                        </v-col>

                         <v-col
                        cols="12"
                        md="6"
                        >
                            <v-text-field :readonly="permissions === 'read:homeowner'"
                                label="Homeowner 1 - Email"
                                color="black"
                                v-model="this.selected_user.PrimaryEmailAddress"
                            />
                        </v-col>
                        <v-col
                        cols="12"
                        md="6"
                        >
                            <v-text-field :readonly="permissions === 'read:homeowner'"
                                label="Homeowner 2 - Email"
                                color="black"
                                v-model="this.selected_user.SecondaryEmailAddress"
                            />
                        </v-col>

                        <v-col
                        cols="12"
                        md="6"
                        >
                            <v-text-field :readonly="permissions === 'read:homeowner'"
                                label="Based on Model"
                                color="black"
                                v-model="section.column_13"
                            />
                        </v-col>
                        <v-col
                        cols="12"
                        md="6"
                        >
                            <v-text-field :readonly="permissions === 'read:homeowner'"
                                label="Allowance"
                                color="black"
                                v-model="section.column_14"
                            />
                        </v-col>


                        <v-col cols="12">
                            <base-material-card
                                icon="mdi-lightbulb"
                                title="Section C Exterior"
                                class="px-5 py-3"
                            >
                                <v-data-table
                                :headers="headersExt"
                                :items="section.exterior_lighting_details"
                                disable-pagination
                                :hide-default-footer="true"
                                >


                                <template v-slot:top>
                                    <v-toolbar
                                        flat
                                    >

                                        <v-spacer></v-spacer>

                                        <v-dialog
                                            v-model="dialog_exterior"
                                            max-width="50%"
                                        >
                                        <v-card>
                                            <v-card-title>
                                                <span class="card-title font-weight-light">Edit Item</span>
                                            </v-card-title>

                                            <v-card-text>
                                                <v-container>
                                                <v-row>
                                                    <v-col
                                                    cols="12"
                                                    md="6"
                                                    >
                                                        <v-select :readonly="permissions === 'read:homeowner'"
                                                            v-model="selected_lighting.item_ceiling_flag"
                                                            :items="['Yes','No','Bid','Undecided']"
                                                            label="Ceiling"
                                                        ></v-select>
                                                    </v-col>
                                                    <v-col
                                                    cols="12"
                                                    md="6"
                                                    >
                                                        <v-select :readonly="permissions === 'read:homeowner'"
                                                            v-model="selected_lighting.item_wall_flag"
                                                            :items="['Yes','No','Bid','Undecided']"
                                                            label="Wall"
                                                        ></v-select>
                                                    </v-col>
                                                    <v-col
                                                    cols="12"
                                                    md="12"
                                                    >
                                                        <v-text-field :readonly="permissions === 'read:homeowner'"
                                                            v-model="selected_lighting.item_specialnotes"
                                                            label="Special Notes"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                </v-container>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                color="blue darken-1"
                                                text
                                                @click="save_exterior"
                                                >
                                                Save
                                                </v-btn>
                                                <v-btn
                                                color="blue darken-1"
                                                text
                                                @click="close_exterior"
                                                >
                                                Close
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                        </v-dialog>

                                    </v-toolbar>
                                    </template>

<!--                                    <template v-slot:[`item.item_ceiling_flag`]="{item}">-->
<!--                                        {{get_value(item.item_ceiling_flag)}}-->
<!--                                    </template>-->
<!--                                    <template v-slot:[`item.item_wall_flag`]="{item}">-->
<!--                                        {{get_value(item.item_wall_flag)}}-->
<!--                                    </template>-->

                                    <template v-slot:[`item.lighting`]="{ item }">
                                        <v-icon
                                            small
                                            class="mr-2"
                                            @click="edit_lighting_exterior(item)"
                                        >
                                            mdi-pencil
                                        </v-icon>
                                    </template>

                                </v-data-table>
                            </base-material-card>
                        </v-col>

                        <v-col cols="12">
                            <base-material-card
                                icon="mdi-lightbulb"
                                title="Section C Interior"
                                class="px-5 py-3"
                            >
                                <v-data-table
                                :headers="headers"
                                :items="section.interior_lighting_details"
                                disable-pagination
                                :hide-default-footer="true"
                                >


                                    <template v-slot:top>
                                        <v-toolbar
                                            flat
                                        >

                                            <v-spacer></v-spacer>

                                            <v-dialog
                                                v-model="dialog_interior"
                                                max-width="50%"
                                            >
                                            <v-card>
                                                <v-card-title>
                                                    <span class="card-title font-weight-light">Edit Item</span>
                                                </v-card-title>

                                                <v-card-text>
                                                    <v-container>
                                                    <v-row>
                                                        <v-col
                                                        cols="12"
                                                        md="4"
                                                        >
                                                            <v-select :readonly="permissions === 'read:homeowner'"
                                                                v-model="selected_lighting.item_ceiling_flag"
                                                                :items="['Yes','No','Bid','Undecided']"
                                                                label="Ceiling"
                                                            ></v-select>
                                                        </v-col>
                                                        <v-col
                                                        cols="12"
                                                        md="4"
                                                        >
                                                            <v-select :readonly="permissions === 'read:homeowner'"
                                                                v-model="selected_lighting.item_wall_flag"
                                                                :items="['Yes','No','Bid','Undecided']"
                                                                label="Wall"
                                                            ></v-select>
                                                        </v-col>
                                                        <v-col
                                                        cols="12"
                                                        md="4"
                                                        >
                                                            <v-select :readonly="permissions === 'read:homeowner'"
                                                                v-model="selected_lighting.item_paddlefan_flag"
                                                                :items="['Yes','No','Bid','Undecided']"
                                                                label="Paddle Fan"
                                                            ></v-select>
                                                        </v-col>
                                                        <v-col
                                                        cols="12"
                                                        md="12"
                                                        >
                                                            <v-text-field :readonly="permissions === 'read:homeowner'"
                                                                v-model="selected_lighting.item_specialnotes"
                                                                label="Special Notes"
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    </v-container>
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn
                                                    color="blue darken-1"
                                                    text
                                                    @click="save_interior"
                                                    >
                                                    Save
                                                    </v-btn>
                                                    <v-btn
                                                    color="blue darken-1"
                                                    text
                                                    @click="close_interior"
                                                    >
                                                    Close
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                            </v-dialog>

                                        </v-toolbar>
                                    </template>

<!--                                    <template v-slot:[`item.item_ceiling_flag`]="{item}">-->
<!--                                        {{get_value(item.item_ceiling_flag)}}-->
<!--                                    </template>-->
<!--                                    <template v-slot:[`item.item_wall_flag`]="{item}">-->
<!--                                        {{get_value(item.item_wall_flag)}}-->
<!--                                    </template>-->
<!--                                    <template v-slot:[`item.item_paddlefan_flag`]="{item}">-->
<!--                                        {{get_value(item.item_paddlefan_flag)}}-->
<!--                                    </template>-->

                                    <template v-slot:[`item.lighting`]="{ item }">
                                        <v-icon
                                            small
                                            class="mr-2"
                                            @click="edit_lighting_interior(item)"
                                        >
                                            mdi-pencil
                                        </v-icon>
                                    </template>

                                </v-data-table>
                            </base-material-card>
                        </v-col>

                    <v-dialog
                        v-model="dialog"
                        max-width="50%"
                      >
                           </v-dialog>

                        </v-row>
                    </v-container>
                </v-form>
                </base-material-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from "axios";

export default {
    name:'VendorSelectionsStreetLighting',
    data () {
      return {
            dialog_exterior: false,
            dialog_interior: false,
            editedIndex:  -1,
            headers: [
                {
                text: 'Location',
                sortable: false,
                class:"primary--text",
                value: 'item_location',
                },
                {
                text: 'Ceiling',
                value: 'item_ceiling_flag',
                sortable: false,
                class:"primary--text",
                },
                {
                text: 'Wall',
                value: 'item_wall_flag',
                sortable: false,
                class:"primary--text",
                },
                {
                text: 'Paddle Fan',
                value: 'item_paddlefan_flag',
                sortable: false,
                class:"primary--text",
                },
                {
                text: 'Special Notes',
                value: 'item_specialnotes',
                sortable: false,
                class:"primary--text",
                },
                {
                text: 'Edit',
                value: 'lighting',
                align: 'end',
                sortable: false,
                class:"primary--text",
                }
            ],
        headersExt: [
                {
                text: 'Location',
                sortable: false,
                class:"primary--text",
                value: 'item_location',
                },
                {
                text: 'Ceiling',
                value: 'item_ceiling_flag',
                sortable: false,
                class:"primary--text",
                },
                {
                text: 'Wall',
                value: 'item_wall_flag',
                sortable: false,
                class:"primary--text",
                },
                {
                text: 'Special Notes',
                value: 'item_specialnotes',
                sortable: false,
                class:"primary--text",
                },
                {
                text: 'Edit',
                value: 'lighting',
                align: 'end',
                sortable: false,
                class:"primary--text",
                }
            ],
            selected_lighting: {
                item_location:'',
                item_ceiling_flag:'',
                item_wall_flag:'',
                item_paddlefan_flag:'',
                item_specialnotes:'',
            },
            default_lighting: {
                item_location:'',
                item_ceiling_flag:'',
                item_wall_flag:'',
                item_paddlefan_flag:'',
                item_specialnotes:'',
            },
            select_items: [
                {
                    item_text:'Yes',
                    item_value:'Yes'
                },
                {
                    item_text:'No',
                    item_value:'No'
                },
                {
                    item_text:'Bid',
                    item_value:'Bid'
                },
                {
                    item_text:'Undecided',
                    item_value:'Undecided'
                }
            ],
        home_id: localStorage.getItem("homeid"),
        alert:false,
        dialog: null,
        valid: null,
        staff_email: 'steve@skelleymarketing.com',
        section_id: "C",
        add_vendor_email:'',
        vendor_email: [],
        selected_vendor: {
            ItemId:0,
            Vendor: '',
            Name:'',
            Email: '',
            ModifiedDate:''
        },
        default_vendor: {
            ItemId:0,
            Vendor: '',
            Name:'',
            Email: '',
            ModifiedDate:''
        },
        section: {
          column_1:'',
          column_2:'',
          column_3:'',
          column_4:'',
          column_5:'',
          column_6:'',
          column_7:'',
          column_8:'',
          column_9:'',
          column_10:'',
          column_11:'',
          column_12:'',
          column_13:'',
          column_14:'',
          exterior_lighting_details:[],
          interior_lighting_details:[],
      },
      selected_user: {
          PrimaryEmailAddress:'',
          SecondaryEmailAddress:'',
          PrimaryFirstName: '',
          PrimaryLastName: '',
          SecondaryFirstName: '',
          SecondaryLastName: '',
          Address:'',
          City:'',
          State: '',
          Zip: '',
          BuildStart: '',
          BuildEnd:'',
          CellNumber:'',
          HomeNumber: '',
          WorkPhone:'',
          Role:'',
          Agent:''
        },
      }
    },

    created () {
      this.getSection()
      this.initialize()
      this.getVendorEmail()
      this.getUserInfo()
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    methods: {

        initialize(){
           this.section.exterior_lighting_details=[
                {
                    item_location:'Front door',
                    item_ceiling_flag: '',
                    item_wall_flag:'',
                    item_specialnotes:'',
                },
                {
                    item_location:'Garage front',
                    item_ceiling_flag:'',
                    item_wall_flag:'',
                    item_specialnotes:'',
                },
                {
                    item_location:'Garage service door',
                    item_ceiling_flag:'',
                    item_wall_flag:'',
                    item_specialnotes:'',
                },
                {
                    item_location:'Patio door deck',
                    item_ceiling_flag:'',
                    item_wall_flag:'',
                    item_specialnotes:'',
                },
                {
                    item_location:'Walkout door',
                    item_ceiling_flag:'',
                    item_wall_flag:'',
                    item_specialnotes:'',
                },
                {
                    item_location:'Other exterior lights',
                    item_ceiling_flag:'',
                    item_wall_flag:'',
                    item_specialnotes:'',
                }
            ],
            this.section.interior_lighting_details=[
                {
                    item_location:'Foyer',
                    item_ceiling_flag:'',
                    item_wall_flag:'',
                    item_paddlefan_flag:'',
                    item_specialnotes:'',
                },
                {
                    item_location:'Mudroom',
                    item_ceiling_flag:'',
                    item_wall_flag:'',
                    item_paddlefan_flag:'',
                    item_specialnotes:'',
                },
                {
                    item_location:'Laundry',
                    item_ceiling_flag:'',
                    item_wall_flag:'',
                    item_paddlefan_flag:'',
                    item_specialnotes:'',
                },
                {
                    item_location:'Dining room',
                    item_ceiling_flag:'',
                    item_wall_flag:'',
                    item_paddlefan_flag:'',
                    item_specialnotes:'',
                },
                {
                    item_location:'Living room',
                    item_ceiling_flag:'',
                    item_wall_flag:'',
                    item_paddlefan_flag:'',
                    item_specialnotes:'',
                },
                {
                    item_location:'Basement Family room',
                    item_ceiling_flag:'',
                    item_wall_flag:'',
                    item_paddlefan_flag:'',
                    item_specialnotes:'',
                },
                {
                    item_location:'Kitchen ceiling',
                    item_ceiling_flag:'',
                    item_wall_flag:'',
                    item_paddlefan_flag:'',
                    item_specialnotes:'',
                },
                {
                    item_location:'Kitchen island',
                    item_ceiling_flag:'',
                    item_wall_flag:'',
                    item_paddlefan_flag:'',
                    item_specialnotes:'',
                },
                {
                    item_location:'Kitchen sink',
                    item_ceiling_flag:'',
                    item_wall_flag:'',
                    item_paddlefan_flag:'',
                    item_specialnotes:'',
                },
                {
                    item_location:'Kitchen pantry',
                    item_ceiling_flag:'',
                    item_wall_flag:'',
                    item_paddlefan_flag:'',
                    item_specialnotes:'',
                },
                {
                    item_location:'Flex room',
                    item_ceiling_flag:'',
                    item_wall_flag:'',
                    item_paddlefan_flag:'',
                    item_specialnotes:'',
                },
                {
                    item_location:'3 or 4 season porch',
                    item_ceiling_flag:'',
                    item_wall_flag:'',
                    item_paddlefan_flag:'',
                    item_specialnotes:'',
                },
                {
                    item_location:'1/2 bath',
                    item_ceiling_flag:'',
                    item_wall_flag:'',
                    item_paddlefan_flag:'',
                    item_specialnotes:'',
                },
                {
                    item_location:'Lower stair landing',
                    item_ceiling_flag:'',
                    item_wall_flag:'',
                    item_paddlefan_flag:'',
                    item_specialnotes:'',
                },
                {
                    item_location:'Upper hall',
                     item_ceiling_flag:'',
                    item_wall_flag:'',
                    item_paddlefan_flag:'',
                    item_specialnotes:'',
                },
                {
                    item_location:'Master bedroom',
                    item_ceiling_flag:'',
                    item_wall_flag:'',
                    item_paddlefan_flag:'',
                    item_specialnotes:'',
                },
                {
                    item_location:'Bedroom #2',
                    item_ceiling_flag:'',
                    item_wall_flag:'',
                    item_paddlefan_flag:'',
                    item_specialnotes:'',
                },
                {
                    item_location:'Bedroom #3',
                    item_ceiling_flag:'',
                    item_wall_flag:'',
                    item_paddlefan_flag:'',
                    item_specialnotes:'',
                },
                {
                    item_location:'Bedroom #4',
                    item_ceiling_flag:'',
                    item_wall_flag:'',
                    item_paddlefan_flag:'',
                    item_specialnotes:'',
                },
                {
                    item_location:'Basement Bedroom',
                    item_ceiling_flag:'',
                    item_wall_flag:'',
                    item_paddlefan_flag:'',
                    item_specialnotes:'',
                },
                {
                    item_location:'Master closet',
                    item_ceiling_flag:'',
                    item_wall_flag:'',
                    item_paddlefan_flag:'',
                    item_specialnotes:'',
                },
                {
                    item_location:'Master bath',
                    item_ceiling_flag:'',
                    item_wall_flag:'',
                    item_paddlefan_flag:'',
                    item_specialnotes:'',
                },
                {
                    item_location:'Main bath',
                    item_ceiling_flag:'',
                    item_wall_flag:'',
                    item_paddlefan_flag:'',
                    item_specialnotes:'',
                },
                {
                    item_location:'Basement bath',
                    item_ceiling_flag:'',
                    item_wall_flag:'',
                    item_paddlefan_flag:'',
                    item_specialnotes:'',
                },
            ]
        },
        getSection(){
            axios
            .get(process.env.VUE_APP_GET_SECTION_URL,
            {
            params: {
                home_id: this.home_id,
                section_id: this.section_id
            }
            })
            .then(response => {

                if (response.data)
                {
                    var result = response.data
                    this.section = JSON.parse(result['JsonDetails'])
                }
            })
            .catch(error => {
            console.log(error)
            })
        },
        getUserInfo(){
            axios
            .get(process.env.VUE_APP_GET_USER_URL_INFO,
            {
              params: {
                home_id: this.home_id
            }
            })
            .then(response => {

                if (response.data)
                {
                    var result = response.data
                    this.selected_user = result[0]
                }
            })
            .catch(error => {
            console.log(error)
            })
        },
        getVendorEmail(){
          axios.get(process.env.VUE_APP_GET_VENDOR_EMAIL_URL, {})
          .then(response => {
            this.vendor_email = response.data
          })
          .catch(error => {
            console.log(error)
          })
          console.log(this.vendor_email)
        },
        updateSection(){
            var body ={
              'home_id':this.home_id,
              'section_id': this.section_id,
              "section":JSON.stringify(this.section),
            }
            axios
            .post(process.env.VUE_APP_UPDATE_SECTION_URL,body)
            .then(response => {
                console.log(response.data)
                this.alert = true
            })
            .catch(error => {
            console.log(error)
            })
        },
        sectionComplete(){
            var body ={
              'home_id':this.home_id,
              'section_id': this.section_id,
              "section":JSON.stringify(this.section),
            }
            axios
            .post(process.env.VUE_APP_UPDATE_SECTION_TWO_URL,body)
            .then(response => {
                console.log(response.data)
                this.alert = true
                this.sendEmail([this.homeowner_emailid],this.staff_email,"home_section_submit_notification",this.this.selected_user.PrimaryFirstName, this.section_id)
            })
            .catch(error => {
            console.log(error)
            })
          this.add_vendor_email = Object.assign({}, this.add_vendor_email)
          this.editedIndex = -1
          this.close()
        },
        close(){
            this.dialog = false
            this.$nextTick(() => {
                this.add_vendor_email = Object.assign({}, this.add_vendor_email)
                this.editedIndex = -1
            })
        },
        sendEmail(to,from,template,content,section){

            var email_body ={
            "to": to,
            "from": from,
            "template_name": template,
            "template_data": {
                "content": content,
                "section": section
            }
            }

            axios
            .post(process.env.VUE_APP_SEND_EMAIL_URL,email_body)
            .then(response => {
            console.log(response.data)
            })
            .catch(error => {
            console.log(error)
            })

        },
        edit_lighting_exterior (lighting) {
          this.editedIndex = this.section.exterior_lighting_details.indexOf(lighting)
          this.selected_lighting = Object.assign({}, lighting)
          console.log(this.selected_lighting),
          this.dialog_exterior = true
        },
        edit_lighting_interior (lighting) {
          this.editedIndex = this.section.interior_lighting_details.indexOf(lighting)
          this.selected_lighting = Object.assign({}, lighting)
          console.log(this.selected_lighting),
          this.dialog_interior = true
        },
        close_exterior () {
          this.dialog_exterior = false
          this.$nextTick(() => {
            this.selected_lighting = Object.assign({}, this.default_lighting)
            this.editedIndex = -1
          })
        },
        close_interior(){
          this.dialog_interior = false
          this.$nextTick(() => {
            this.selected_lighting = Object.assign({}, this.default_lighting)
            this.editedIndex = -1
          })
        },
        save_exterior(){
          if (this.editedIndex > -1) {
            Object.assign(this.section.exterior_lighting_details[this.editedIndex], this.selected_lighting)
          } else {
            this.cost_breakdown.push(this.selected_lighting)
          }
          this.close_exterior()
          this.updateSection()
        },
        save_interior(){
          if (this.editedIndex > -1) {
            Object.assign(this.section.interior_lighting_details[this.editedIndex], this.selected_lighting)
          } else {
            this.cost_breakdown.push(this.selected_lighting)
          }
          this.close_interior()
          this.updateSection()
        }

    },
    computed: {
      homeowner_emailid() {
        return this.selected_user.PrimaryEmailAddress
      },
      permissions() {
            return localStorage.getItem('permissions')
      },
    }
}
</script>